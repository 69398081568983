import styled from 'styled-components'
import Table from 'components/Table/Table'
import { capitalizeFirstLetter } from 'utils/textProcess'
import HeaderTable from 'components/Table/HeaderTable'
import { P2 } from 'components/Typography'

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  heigth: 50vh;
  color: ${({ theme }) => theme.colors.neutral.black};
`

const EmptyTableData = styled.div`
  padding: 23px 30px;
  background-color: ${({ theme }) => theme.colors.neutral.white};
`

const ScrolleableTable = styled.div`
  max-height: 450px;
  overflow: scroll;
`
const CapitalizedP2 = styled(P2)`
  text-transform: capitalize;
`

const CustomHeader = styled(HeaderTable)`
  padding: 14px 44px;
`

const formatData = ({ contactos }) =>
  contactos.map((contacto) => ({
    Nombre: <CapitalizedP2>{contacto.nombre.toLowerCase()}</CapitalizedP2>,
    Cargo: <P2>{capitalizeFirstLetter(contacto.cargo.toLowerCase())}</P2>,
    Telefono: <P2>{contacto.telefono.toLowerCase()}</P2>,
    Email: <P2>{contacto.email.toLowerCase()}</P2>,
  }))

const headers = [
  { name: 'Nombre', isOrderable: false },
  { name: 'Cargo', isOrderable: false },
  { name: 'Telefono', isOrderable: false },
  { name: 'Email', isOrderable: false },
]

const ContactosTable = ({ contactos }) => {
  return (
    <TableWrapper>
      <CustomHeader
        numerate={false}
        headers={headers}
        gridTemplate="1fr, 1fr, 1fr, 1fr"
      />
      {contactos?.length > 0 ? (
        <ScrolleableTable>
          <Table
            data={formatData({ contactos })}
            numerate={false}
            gridTemplate="1fr, 1fr, 1fr, 1fr"
          />
        </ScrolleableTable>
      ) : (
        <EmptyTableData>No se encontraron articulos</EmptyTableData>
      )}
    </TableWrapper>
  )
}

export default ContactosTable
