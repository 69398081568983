import {} from 'react'
import DropdownNotification from 'components/Notification/DropdownNotification'
import styled from 'styled-components'
import { useNotificaciones } from 'contexts/NotificacionesContext'

const WrapperNotification = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.neutral.white};
  box-sizing: border-box;
  padding-top: 16px;
`

const NotificationCenter = ({ className, sectionName = 'notificaciones' }) => {
  const { setNotificaciones, notificaciones } = useNotificaciones()
  // let notificationArray = []
  function limpiarNotificacion(nombreLlave) {
    const newNotificaciones = {
      ...JSON.parse(localStorage.getItem(sectionName)),
    }
    delete newNotificaciones[nombreLlave]
    localStorage.setItem(sectionName, JSON.stringify(newNotificaciones))
    setNotificaciones(newNotificaciones)
  }

  return (
    <WrapperNotification className={className}>
      {Object.values(notificaciones).map((notification, index) => {
        // modificar contenido del primer if y/o pedir estructura diferente si se envían más notificaciones desde la base de datos que carritosSinConsolidar.
        if (Object.keys(notification).length === 0) {
          {
            notification && (
              <DropdownNotification
                key={index}
                text="Hay carritos pendientes de confirmar."
                closeAction={() => limpiarNotificacion('carritosSinConsolidar')}
              />
            )
          }
        } else {
          return (
            <DropdownNotification
              key={index}
              closeAction={() =>
                limpiarNotificacion(notification.notificationName)
              }
              {...notification}
            />
          )
        }
      })}
    </WrapperNotification>
  )
}

export default NotificationCenter
