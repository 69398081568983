import { useState } from 'react'
import styled from 'styled-components'
import { Row } from 'components/layout/FlexComponents'
import Table from 'components/Table/Table'
import { P4 } from 'components/Typography'
import {
  Edit2,
  Eye,
  FileText,
  Trash2,
} from 'feather-icons-react/build/IconComponents'
import { usePedidos } from 'contexts/PedidosContext'
import { useAuth } from 'contexts/AuthContext'
import { useCronograma } from 'contexts/CronogramaContext'
import LinkUnderline from 'components/Button/LinkUnderline'
import { useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'
import Loading from 'components/Loading/Loading'
import HeaderTable from 'components/Table/HeaderTable'
import RotatingLoading from 'components/Loading/Loading'
import StatusTag from 'components/Tag/StatusTag'
import DeletePedidoModal from 'components/Modal/DeletePedidoModal'
import EditPedidoModal from 'components/Modal/EditPedidoModal'
import { useModal } from 'contexts/ModalContext'
import { useFilters } from 'contexts/FiltersContext'
import { Button } from 'components/index'
import {
  ESTADOS_DESHABILITAR_RECEPCION_STR,
  ESTADOS_OCULTAR_COMPROBANTES_STR,
} from 'utils/EstadosPedidos'
import { useWindowWidth } from 'contexts/WindowWidthContext'
import { Tooltip } from 'react-tooltip'

const EmptyTableData = styled.div`
  padding: 23px 30px;
  background-color: ${({ theme }) => theme.colors.white};
`

const ProveedoresItem = styled(Row)`
  align-items: center;
  gap: 6px;
`

const DataBoxNumberLink = styled(LinkUnderline)`
  color: ${({ theme }) => theme.colors.neutral.black};
`

const Nombre = styled(P4)`
  font-weight: bold;
`

const EyeIcon = styled(Eye)`
  width: 18px;
  height: 18px;
  margin: 0 4px;
  cursor: pointer;
`

const ComprobanteIcon = styled(FileText)`
  width: 18px;
  height: 18px;
  margin: 0 4px;
  cursor: pointer;
`

const EditIcon = styled(Edit2)`
  width: 18px;
  height: 18px;
  margin: 0 4px;
  cursor: pointer;
`

const DeleteIcon = styled(Trash2)`
  width: 18px;
  height: 18px;
  margin: 0 4px;
  cursor: pointer;
`

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const LoadingContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  padding-left: 16px;
`

const ButtonIrAComprar = styled(Button)`
  width: 116px;
  padding: 8px 20px;
`

const EmptyPage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const PercentageIcon = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: transparent;
  border: 2px solid
    ${({ theme, $porcentajeRecepcion }) =>
      $porcentajeRecepcion === 0
        ? theme.colors.neutral.gray
        : $porcentajeRecepcion === 100
          ? theme.colors.alarmsStatuses.green
          : $porcentajeRecepcion > 0
            ? theme.colors.alarmsStatuses.yellow
            : theme.colors.primary.lightBlue};
  background-color: ${({ theme, $porcentajeRecepcion }) =>
    $porcentajeRecepcion === 100
      ? theme.colors.alarmsStatuses.green
      : theme.colors.neutral.white};
  color: ${({ theme, $porcentajeRecepcion }) =>
    $porcentajeRecepcion === 0
      ? theme.colors.neutral.gray
      : $porcentajeRecepcion === 100
        ? theme.colors.neutral.white
        : $porcentajeRecepcion > 0
          ? theme.colors.alarmsStatuses.yellow
          : theme.colors.primary.lightBlue};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-family: 'Roboto-bold';
`

const RecepcionButton = styled(Button)`
  min-width: 45px;
`

const Porcentaje = ({ value }) => {
  return (
    <>
      <PercentageIcon
        $porcentajeRecepcion={value}
        data-tooltip-id="porcentajeRecepcion"
        data-tooltip-content="Este porcentaje es una estimación aproximada y no representa la cantidad exacta de mercadería"
        data-tooltip-place="top"
      >
        {value + '%'}
      </PercentageIcon>
      <Tooltip id="porcentajeRecepcion" delayShow={50} />
    </>
  )
}

const formatData = ({
  idCronograma,
  pedidos,
  onClickPedidosOC,
  onClickComprobantesDetalle,
  onClickConfirmadosDetalle,
  iconLoading,
  setIconLoading,
  handleDeleteProducto,
  handleDeletePedidoGenerado,
  handleEditPedido,
  handleOnClickRecepcion,
  user,
  width,
}) => {
  return pedidos
    ?.filter((pedido) => pedido.id_proveedor !== null)
    ?.map((pedido) => ({
      OC:
        pedido?.estado?.descripcion !== 'Confeccionado' ? (
          <DataBoxNumberLink
            onClick={() =>
              onClickPedidosOC(
                user.rol.id_rol === '1' || user.rol.id_rol === '2'
                  ? pedido.ordenDeCompraVisible
                  : pedido.ordenDeCompra,
                pedido.id,
              )
            }
          >
            {user.rol.id_rol === '1' || user.rol.id_rol === '2'
              ? pedido.ordenDeCompraVisible
              : pedido.ordenDeCompra}
          </DataBoxNumberLink>
        ) : (
          <></>
        ),
      Estado:
        pedido?.estado?.descripcion === 'Facturado' &&
        (user?.rol?.id_rol === '1' || user?.rol?.id_rol === '2') &&
        pedido.tipo_proveedor === '0' ? (
          <StatusTag estado="Despachado" />
        ) : pedido?.estado?.descripcion === 'Confeccionado' ? (
          <StatusTag
            estado={
              pedido.idCronograma === idCronograma
                ? pedido.estado.descripcion
                : 'No Consolidado'
            }
          />
        ) : (
          <StatusTag estado={pedido?.estado?.descripcion} />
        ),
      Operador: (
        <ProveedoresItem key={pedido.proveedor}>
          <Nombre>{pedido.proveedor}</Nombre>
        </ProveedoresItem>
      ),
      ...(width > 440
        ? {
            Fecha: (
              <P4>
                {pedido.fecha_confeccion
                  ? dayjs(pedido.fecha_confeccion).format('DD/MM/YYYY')
                  : ''}
              </P4>
            ),
            ...(user?.rol?.id_rol === '3'
              ? {
                  Retiro: <P4>{pedido.entrega}</P4>,
                }
              : {}),
            ...(pedido.tipo_proveedor === '1' ||
            ESTADOS_DESHABILITAR_RECEPCION_STR.includes(
              pedido?.estado?.descripcion?.toLowerCase(),
            )
              ? { PorcentajeRecepcion: <></> }
              : {
                  PorcentajeRecepcion: (
                    <Porcentaje value={pedido.porcentajeRecepcion} />
                  ),
                }),
            ...(user?.rol?.id_rol === '3'
              ? {
                  Recepción: (
                    <>
                      {pedido.tipo_proveedor !== '1' && (
                        <RecepcionButton
                          type="primary"
                          size="small"
                          text={'Recepción'}
                          disabled={
                            pedido.tipo_proveedor === '1' ||
                            pedido.porcentajeRecepcion === 100 ||
                            ESTADOS_DESHABILITAR_RECEPCION_STR.includes(
                              pedido?.estado?.descripcion?.toLowerCase(),
                            ) ||
                            (pedido.estado_recepcion === '1' &&
                              user?.rol?.id_rol === '3')
                          }
                          onClick={() =>
                            handleOnClickRecepcion(
                              pedido.id,
                              pedido.ordenDeCompra,
                            )
                          }
                        />
                      )}
                    </>
                  ),
                }
              : {}),
          }
        : {}),
      Accionables:
        pedido.id === iconLoading ? (
          <LoadingContainer>
            <RotatingLoading />
          </LoadingContainer>
        ) : (
          <>
            {width > 440 && (
              <>
                {(pedido?.estado?.descripcion?.toLowerCase() === 'confirmado' ||
                  pedido?.estado?.descripcion === 'Confeccionado' ||
                  pedido?.estado?.descripcion === 'No Confirmado') && (
                  <EyeIcon
                    onClick={() => {
                      if (
                        pedido?.estado?.descripcion === 'Confeccionado' ||
                        pedido?.estado?.descripcion === 'No Confirmado'
                      ) {
                        onClickConfirmadosDetalle(
                          pedido.id,
                          pedido.id_proveedor,
                          pedido?.estado?.descripcion === 'No Confirmado',
                        )
                      } else {
                        onClickPedidosOC(
                          user.rol.id_rol === '1' || user.rol.id_rol === '2'
                            ? pedido.ordenDeCompraVisible
                            : pedido.ordenDeCompra,
                          pedido.id,
                        )
                      }
                    }}
                  />
                )}
                {!ESTADOS_OCULTAR_COMPROBANTES_STR.includes(
                  pedido?.estado?.descripcion?.toLowerCase(),
                ) && (
                  <ComprobanteIcon
                    onClick={() =>
                      onClickComprobantesDetalle(
                        pedido.ordenDeCompra,
                        pedido.id,
                        pedido.tipo_proveedor,
                      )
                    }
                  />
                )}
              </>
            )}
            {pedido?.estado?.descripcion?.toLowerCase() === 'confeccionado' &&
              pedido.idCronograma === idCronograma && (
                <>
                  <EditIcon
                    onClick={async () => {
                      setIconLoading(pedido.id)
                      handleEditPedido(pedido.id_proveedor)
                      setIconLoading('')
                    }}
                  />
                  <DeleteIcon
                    onClick={async () => {
                      setIconLoading(pedido.id)
                      handleDeleteProducto(pedido.id_proveedor, pedido.id)
                      setIconLoading('')
                    }}
                  />
                </>
              )}
            {pedido?.estado?.descripcion?.toLowerCase() === 'generado' &&
              pedido.idCronograma === idCronograma &&
              user.rol.id_rol === '3' && (
                <>
                  <DeleteIcon
                    onClick={async () => {
                      setIconLoading(pedido.id)
                      handleDeletePedidoGenerado(pedido.id)
                      setIconLoading('')
                    }}
                  />
                </>
              )}
          </>
        ),
    }))
}

const MisPedidosTable = ({
  currentPage,
  activeOrder,
  handleSetActiveOrder,
}) => {
  const navigate = useNavigate()
  const { user } = useAuth()
  const { cronograma } = useCronograma()
  const { getMisPedidos, misPedidos, pedidosLoading, setRecepcionPedido } =
    usePedidos()
  const [iconLoading, setIconLoading] = useState(false)
  const { width } = useWindowWidth()
  const { openModal, isModalOpen } = useModal()
  const [isDeletePedidoModalOpen, setIsDeletePedidoModalOpen] = useState(false)
  const [isEditPedidoModalOpen, setIsEditPedidoModalOpen] = useState(false)
  const [idProveedorPedidoToChange, setIdProveedorPedidoPedidoToChange] =
    useState(false)
  const [idPedidoToChange, setIdPedidoToChange] = useState(false)
  const { pedidosFilters } = useFilters()
  const [idOcCabeceraToDelete, setIdOcCabeceraToDelete] = useState()

  const afterClose = () => {
    setIsDeletePedidoModalOpen(false)
    setIsEditPedidoModalOpen(false)
    setIdOcCabeceraToDelete(null)
    setTimeout(function () {
      getMisPedidos({
        ...pedidosFilters,
        currentPage,
      })
    }, 500)
  }

  const handleDeleteProducto = (idProveedor, idProducto) => {
    openModal()
    setIsDeletePedidoModalOpen(true)
    setIdProveedorPedidoPedidoToChange(idProveedor)
    setIdPedidoToChange(idProducto)
  }

  const handleDeletePedidoGenerado = (idOcCabecera) => {
    openModal()
    setIsDeletePedidoModalOpen(true)
    setIdProveedorPedidoPedidoToChange(null)
    setIdOcCabeceraToDelete(idOcCabecera)
  }

  const handleEditPedido = (idProveedor) => {
    openModal()
    setIsEditPedidoModalOpen(true)
    setIdProveedorPedidoPedidoToChange(idProveedor)
  }
  const handleOnClickRecepcion = (id, ordenCompra) => {
    setRecepcionPedido({})
    localStorage.setItem('recepcionPedido', {})
    navigate(`/pedidos/recepcion/${id}`, {
      state: { ordenCompra },
    })
  }

  const headers = [
    {
      name: 'OC',
      tag: 'ordenDeCompra',
    },
    {
      name: 'Estado',
      tag: 'estado',
    },
    {
      name: 'Proveedor',
      tag: 'proveedor',
    },
    ...(width > 440
      ? [
          {
            name: 'Fecha',
            tag: 'fecha_confeccion',
          },
          ...(user?.rol?.id_rol === '3'
            ? [
                {
                  name: 'Retiro',
                  tag: 'retiro',
                },
              ]
            : []),
          {
            name: 'Recepción',
            tag: 'recepcion',
          },
        ]
      : []),
    ...(user?.rol?.id_rol === '3'
      ? [
          {
            name: '',
            tag: '',
          },
        ]
      : []),
  ]

  const onClickPedidosOC = (ordenCompra, id) => {
    navigate(`/mis-pedidos/OC-${ordenCompra}`, {
      state: id,
    })
  }

  const onClickComprobantesDetalle = (oc, idOcCompra, tipoProveedor) => {
    navigate(`/mis-pedidos/comprobantes/${oc}`, {
      state: { idOcCompra, tipoProveedor },
    })
  }

  const onClickConfirmadosDetalle = (
    idCarrito,
    idProveedor,
    isNoConfirmado,
  ) => {
    if (isNoConfirmado) {
      navigate(`/mis-pedidos/no-confirmados/${idCarrito}`, {
        state: { idCarrito, idProveedor },
      })
    } else {
      navigate(`/mis-pedidos/confirmados/${idCarrito}`, {
        state: { idCarrito, idProveedor },
      })
    }
  }

  return (
    <TableWrapper>
      {pedidosLoading ? (
        <Loading />
      ) : misPedidos?.pedidos?.length > 0 ? (
        <>
          <HeaderTable
            headers={headers}
            gridTemplate={
              width < 440
                ? user.rol.id_rol === '3'
                  ? '25% 25% 25% 25%' // Ajuste para rol 3 y resolución < 440px
                  : '22% 26% 30% 10%' // Ajuste para otros roles y resolución < 440px
                : user.rol.id_rol === '3'
                  ? '10% 10% 20% 10% 15% 5% 12% 5%' // Ajuste para rol 3 y resolución >= 440px
                  : user.rol.id_rol === '2'
                    ? '10% 20% 25% 15% 10% 5%'
                    : '12% 15% 36% 10% 12% 10%' // Ajuste para otros roles y resolución >= 440px
            }
            activeOrder={activeOrder}
            handleSetActiveOrder={handleSetActiveOrder}
            camposOrden={misPedidos.pager.campos_orden}
          />
          <Table
            data={formatData({
              idCronograma: cronograma.id_cronograma,
              pedidos: misPedidos.pedidos,
              onClickPedidosOC,
              onClickComprobantesDetalle,
              onClickConfirmadosDetalle,
              iconLoading,
              setIconLoading,
              handleDeleteProducto,
              handleDeletePedidoGenerado,
              handleEditPedido,
              handleOnClickRecepcion,
              user,
              width,
            })}
            gridTemplate={
              width < 440
                ? user.rol.id_rol === '3' || user.rol.id_rol === '4'
                  ? '25% 25% 25% 25%' // Ajuste para rol 3 y resolución < 440px
                  : '22% 26% 26% 14%' // Ajuste para otros roles y resolución < 440px
                : user.rol.id_rol === '3' || user.rol.id_rol === '4'
                  ? '10% 10% 20% 10% 15% 5% 12% 5%' // Ajuste para rol 3 y resolución >= 440px
                  : user.rol.id_rol === '2'
                    ? '10% 20% 25% 15% 10% 5%'
                    : '12% 15% 36% 10% 12% 10%' // Ajuste para otros roles y resolución >= 440px
            }
          />

          {isModalOpen && isDeletePedidoModalOpen && (
            <DeletePedidoModal
              idProveedor={idProveedorPedidoToChange}
              idProducto={idPedidoToChange}
              idOcCabecera={idOcCabeceraToDelete}
              afterClose={afterClose}
            />
          )}
          {isModalOpen && isEditPedidoModalOpen && (
            <EditPedidoModal
              idProveedor={idProveedorPedidoToChange}
              idCronograma={cronograma.id_cronograma}
              afterClose={afterClose}
            />
          )}
        </>
      ) : (
        <TableWrapper>
          <EmptyPage>
            <EmptyTableData>No hay Pedidos aún</EmptyTableData>
            <ButtonIrAComprar
              onClick={() => navigate('/comprar')}
              type="secondary"
              text="Ir a Comprar"
              size="small"
            />
          </EmptyPage>
        </TableWrapper>
      )}
    </TableWrapper>
  )
}

export default MisPedidosTable
