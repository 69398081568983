import { useEffect } from 'react'
import styled from 'styled-components'
import { SearchAndFilterTableProvider } from 'contexts/SearchAndFilterTableContext'
import HeaderPedidoAgrupacion from '../HeaderComprobantes/HeaderPedidoAgrupacion'
import DetallesSubasociadasTable from './DetallesSubasociadasTable'
import ExpandableTables from 'components/ExpandableTables/ExpandableTables'
import PedidosAgrupadosTable from '../PedidosAgrupadosTable'
import { useAuth } from 'contexts/AuthContext'
import { useCronograma } from 'contexts/CronogramaContext'
import { usePedidos } from 'contexts/PedidosContext'
import dayjs from 'dayjs'
import Loading from 'components/Loading/Loading'
import { useLocation, useParams } from 'react-router-dom'
import { useWindowWidth } from 'contexts/WindowWidthContext'
import {} from 'utils/textProcess'
import ContactosTable from '../ContactosTable'
import { H3, H5 } from 'components/Typography'

const WrapperInfo = styled.div`
  align-items: center;
  justify-content: space-between;
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  row-gap: 32px;
  padding: 14px 44px;
  color: ${({ theme }) => theme.colors.primary.darkBlue};

  @media (max-width: 440px) {
    flex-wrap: unset;
    flex-direction: column;
    row-gap: 14px;
  }
`

const InfoBox = styled.div`
  border-left: ${({ theme }) => theme.colors.primary.darkBlue} 3px solid;
  background-color: ${({ theme }) => theme.colors.neutral.almostWhite};
  border-radius: 0 5px 5px 0;
  padding: 12px 8px;
  display: inline-block;
  width: ${(props) => (props.$isfullwidth ? '100%' : 'calc(25% - 28px)')};

  @media (max-width: 440px) {
    width: 100%;
  }
`

const TitleSection = styled(H3)`
  border-left: ${({ theme }) => theme.colors.primary.darkBlue} 3px solid;
  background-color: ${({ theme }) => theme.colors.neutral.almostWhite};
  border-radius: 0 5px 5px 0;
  padding: 12px 8px;
  display: inline-block;
`

const ContactTable = styled.div`
  display: flex;
  flex-direction: column;
  align-items: space-between;
  gap: 10px;
  padding: 11px;
`

const WrapperAgrupacionPage = styled.div`
  display: flex;
  width: 80%;
  flex-direction: row;
  border-radius: 20px;
  gap: 32px;
  padding: 20px 40px;
  background-color: ${({ theme }) => theme.colors.neutral.almostWhite};
  margin: 50px auto;

  @media (max-width: 440px) {
    padding: 20px;
    margin: 36px auto;
  }
`

const TableSection = styled.div`
  width: 100%;
`

const WrapperTables = styled.div`
  width: 100%;
  display: flex;
  background-color: ${({ theme }) => theme.colors.neutral.white};
  flex-direction: column;
  margin-bottom: 40px;
`

const EmptyTableData = styled.div`
  padding: 23px 30px;
  background-color: ${({ theme }) => theme.colors.white};
`

const PedidosAgrupacionOrdenDeCompra = () => {
  const { user, token } = useAuth()
  const { cronograma } = useCronograma()
  const { width } = useWindowWidth()
  const { getOcAgrupacion, setOcAgrupacion, ocAgrupacion, pedidosLoading } =
    usePedidos()
  const { ordenCompra } = useParams()
  const location = useLocation()
  const id = location.state

  useEffect(() => {
    const fetchOcAgrupacion = async () => {
      try {
        if (user && id) {
          getOcAgrupacion(id)
        }
      } catch (error) {
        setOcAgrupacion({})
        console.error('Error al obtener los pedidos: ', error)
      }
    }
    if (user.rol && cronograma?.id_cronograma) {
      fetchOcAgrupacion()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, token, ordenCompra])

  return pedidosLoading ? (
    <Loading />
  ) : ocAgrupacion && ocAgrupacion.pedido ? (
    <SearchAndFilterTableProvider>
      <WrapperAgrupacionPage>
        <TableSection>
          <HeaderPedidoAgrupacion
            oc={{
              proveedor:
                user.rol.id_rol === '5'
                  ? ocAgrupacion.pedido.afiliado
                  : ocAgrupacion.pedido.proveedor,
              tipoProveedor: ocAgrupacion.pedido.tipo_proveedor,
              ordenDeCompra: ordenCompra,
              estado: ocAgrupacion.pedido?.estado,
              estadoRecepcion: ocAgrupacion.pedido.estadoRecepcion,
              porcentajeRecepcion: ocAgrupacion.pedido.porcentajeRecepcion,
              totales: ocAgrupacion.pedido.totales,
              fecha: dayjs(ocAgrupacion.pedido.fechaConfeccion).format(
                'DD/MM/YYYY',
              ),
              idOcCompra: ocAgrupacion.pedido.id,
            }}
          />
          {width > 440 && (
            <ExpandableTables
              items={[
                {
                  title: 'Pedidos Agrupación',
                  component: (
                    <WrapperTables>
                      <PedidosAgrupadosTable
                        pedidos={ocAgrupacion.pedido.detalle}
                        totales={ocAgrupacion.pedido.totales}
                      />
                    </WrapperTables>
                  ),
                },
                ...(user.rol.nombre === 'Coordinadora'
                  ? [
                      {
                        title: 'Detalles de subasociadas',
                        component: (
                          <WrapperTables>
                            <DetallesSubasociadasTable
                              idOcCabecera={ocAgrupacion.pedido.id}
                              pedidos={ocAgrupacion.pedido.subasociadas}
                            />
                          </WrapperTables>
                        ),
                      },
                    ]
                  : []),
                {
                  title: 'Información',
                  component: (
                    <WrapperTables>
                      <WrapperInfo>
                        {[
                          {
                            titulo: 'Razón Social',
                            valor:
                              ocAgrupacion?.pedido?.cabecera?.razon_social ||
                              'Sin info',
                          },
                          {
                            titulo: 'CUIT',
                            valor:
                              ocAgrupacion?.pedido?.cabecera?.cuit ||
                              'Sin info',
                          },
                          {
                            titulo: 'Localidad',
                            valor:
                              ocAgrupacion?.pedido?.cabecera?.location
                                ?.provincia || 'Sin info',
                          },
                          {
                            titulo: 'Dirección',
                            valor:
                              ocAgrupacion?.pedido?.cabecera?.domicilio ||
                              'Sin info',
                          },

                          {
                            titulo: 'Categoría de IVA',
                            valor:
                              ocAgrupacion?.pedido?.cabecera?.categ_iva ||
                              'Sin info',
                          },
                          {
                            titulo: 'Código postal',
                            valor:
                              ocAgrupacion?.pedido?.cabecera?.cod_postal ||
                              'Sin info',
                          },
                          {
                            titulo: 'Fax',
                            valor:
                              ocAgrupacion?.pedido?.cabecera?.fax || 'Sin info',
                          },
                          {
                            titulo: 'Teléfono',
                            valor:
                              ocAgrupacion?.pedido?.cabecera?.telefono ||
                              'Sin info',
                          },
                        ].map((item, index) => (
                          <InfoBox key={index}>
                            <H5>{item.titulo}</H5>
                            {item.valor && <strong>{item.valor}</strong>}
                          </InfoBox>
                        ))}
                      </WrapperInfo>

                      <ContactTable>
                        <TitleSection>Contactos</TitleSection>
                        {ocAgrupacion?.pedido?.contactos && (
                          <ContactosTable
                            contactos={ocAgrupacion?.pedido?.contactos}
                          />
                        )}
                      </ContactTable>
                    </WrapperTables>
                  ),
                },
              ]}
              openTables={['Pedidos Agrupación']}
            />
          )}
        </TableSection>
      </WrapperAgrupacionPage>
    </SearchAndFilterTableProvider>
  ) : (
    <EmptyTableData>No hay datos</EmptyTableData>
  )
}

export default PedidosAgrupacionOrdenDeCompra
