import styled from 'styled-components'
import { FilterComponent } from 'components/FilterComponent/FilterComponent'
import { useAuth } from 'contexts/AuthContext'
import SearchInput from 'components/SearchBar/SearchInput'

const FilterWrapper = styled.div`
  width: 20%;

  @media (max-width: 440px) {
    width: 100%;
  }
`

const SearchTableInput = styled(SearchInput)`
  color: ${({ theme }) => theme.colors.primary.lighterBlue};
  border: 3px solid ${({ theme }) => theme.colors.primary.darkBlue};
  width: auto;
  margin: 6px 0 21px 0;
`

const FilterSection = ({
  filters,
  setFilters,
  searchValue,
  setSearchValue,
  selectedTab = false,
  activeTab = 1,
}) => {
  const { user } = useAuth()
  return (
    <FilterWrapper>
      <SearchTableInput
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
        placeholder={
          user.rol.id_rol === '5'
            ? 'Buscar por asociada'
            : 'Buscar por proveedor'
        }
      />
      <FilterComponent
        setFilters={setFilters}
        filters={filters}
        onlyOneOption={true}
        selectedTab={selectedTab}
        filtersOptions={{
          ...(user.rol.id_rol !== '4' && user.rol.id_rol !== '5'
            ? {
                Pedidos: [
                  {
                    name: 'Todos',
                    tag: 'Todos',
                    key: 'todos',
                    default: true,
                  },
                  {
                    name: 'En cronograma actual',
                    tag: 'En cronograma actual',
                    key: 'cronograma_actual',
                    default: false,
                  },
                ],
              }
            : {}),
          ...(user.rol.id_rol !== '4' && user.rol.id_rol !== '5'
            ? {
                'Tipos de proveedores': [
                  {
                    name: 'Todos los proveedores',
                    tag: 'Todos los proveedores',
                    key: 'todos_provedores',
                    default: true,
                  },
                  {
                    name: 'Operación directa',
                    tag: 'Operación directa',
                    key: 'operación_directa',
                    default: false,
                  },
                  {
                    name: 'Marcas líderes',
                    tag: 'Marcas líderes',
                    key: 'marcas_líderes',
                    default: false,
                  },
                ],
              }
            : {}),
          Estados: [
            {
              name: 'Todos',
              tag: 'Todos los estados',
              key: 'todos_estados',
              default: true,
            },
            ...(user.rol.id_rol !== '3' && user.rol.id_rol !== '5'
              ? [
                  {
                    name: 'Confirmado',
                    tag: 'Confirmado',
                    key: 'confeccion',
                    default: false,
                  },
                  {
                    name: 'No Consolidados / No confirmados',
                    tag: 'No Consolidado',
                    key: 'no_consolidado',
                    default: false,
                  },
                ]
              : []),
            ...(user.rol.id_rol !== '5'
              ? [
                  {
                    name: 'Generado',
                    tag: 'Generado',
                    key: 'generado',
                    default: false,
                  },
                ]
              : []),
            {
              name: 'Aprobado',
              tag: 'Aprobado',
              key: 'aprobado',
              default: false,
            },
            {
              name: 'Desaprobado',
              tag: 'Desaprobado',
              key: 'desaprobado',
              default: false,
            },
            {
              name: 'Cancelado',
              tag: 'Cancelado',
              key: 'cancelado',
              default: false,
            },
            {
              name: 'En Preparación',
              tag: 'En Preparación',
              key: 'en_preparacion',
              default: false,
            },
            {
              name: 'Facturado',
              tag: 'Facturado',
              key: 'facturado',
              default: false,
            },
            ...[
              ...(user?.rol?.id_rol === '2' ||
              (user?.rol?.id_rol === '1' && activeTab === 1)
                ? [
                    {
                      name: 'Despachado',
                      tag: 'Despachado',
                      key: 'despachado',
                      default: false,
                    },
                  ]
                : []),
            ],
          ],
          Fecha: [
            {
              name: 'Histórico',
              tag: 'Histórico',
              key: 'historico',
              default: true,
            },
            {
              name: 'Este mes',
              tag: 'Este mes',
              key: 'este_mes',
              default: false,
            },
            {
              name: 'Mes pasado',
              tag: 'Mes pasado',
              key: 'mes_pasado',
              default: false,
            },
            {
              name: 'Período',
              tag: 'Período',
              key: 'periodo',
              default: {
                fecha_inicio: '',
                fecha_fin: '',
              },
            },
          ],
        }}
      />
    </FilterWrapper>
  )
}

export default FilterSection
